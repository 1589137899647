import axios from "axios";
import headers from "./Headers";

function getQuotes() {
  return axios.get("quotes");
}

function createQuote(form) {
  headers.contentType = "multipart/form-data";
  return axios.post("quotes", form, headers);
}

function showQuote(id) {
  return axios.get(`quotes/${id}`, headers);
}

function editQuote(id, form) {
  headers.contentType = "multipart/form-data";
  return axios.patch(`quotes/${id}`, form, headers);
}

function deleteQuote(id) {
  return axios.delete(`quotes/${id}`, headers);
}

export { getQuotes, createQuote, showQuote, editQuote, deleteQuote };
